/*!
* Color mode toggler for Bootstrap's docs (https://getbootstrap.com/)
* Copyright 2011-2023 The Bootstrap Authors
* Licensed under the Creative Commons Attribution 3.0 Unported License.
*/

(() => {
    'use strict'
    const storedTheme = localStorage.getItem('theme')
    
    const getPreferredTheme = () => {
        if(storedTheme) {
            return storedTheme
        }
        
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
    
    const setTheme = (theme) => {
        if(theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme)
        }
    }
    
    const showActiveTheme = (theme, focus = false) => {
        const themeSwitcher = document.querySelectorAll('[data-bs-theme-value]')
        
        if(!themeSwitcher) {
            return
        }
        
        themeSwitcher.forEach((el) => {
            const t = el.getAttribute('data-bs-theme-value')
            if(t === theme)
                el.style.display = 'none';
            else
                el.style.display = '';
        })
    }
    const initThemeToggler = () => {
        showActiveTheme(getPreferredTheme())
        
        document.querySelectorAll('[data-bs-theme-value]')
            .forEach((el) => {
                el.addEventListener('click', () => {
                    const theme = el.getAttribute('data-bs-theme-value')
                    localStorage.setItem('theme', theme)
                    setTheme(theme)
                    showActiveTheme(theme)
                })
            })
    }
    
    setTheme(getPreferredTheme())
    
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
        if(storedTheme !== 'light' || storedTheme !== 'dark') {
            setTheme(getPreferredTheme())
        }
    })
    
    if(document.readyState === 'loading') {
        // Loading hasn't finished yet.
        document.addEventListener('DOMContentLoaded', initThemeToggler);
    } else {
        // `DOMContentLoaded` has already fired.
        initThemeToggler();
    }
})()
